<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                {{ $store.state.sysenv.codePropagated.name }}
                <small>Code</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd()"></button>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            item-key="sn"
            hide-actions
            draggable
            :headers="headers"
            :items="codeIndex"
            @row-move-to="onRowMoved"
            @sorted="onSorted"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus(props)">
                <td v-if="codePropagated.code === 'ALL'">{{ props.item.groupName }}</td>
                <td style="text-align: center;" v-if="codePropagated.code !== 'ALL'" class="handle">
                  <button class="jcon_order"></button>
                </td>
                <td style="text-align: center;">{{ props.item.no }}</td>
                <td>{{ props.item.code }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.desc }}</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>

      <j-form-modal
        title="Code Index"
        ref="formHandler"
        @create="add()"
        @edit="edit()"
        @delete="del()"
        @cancel="cancel()"
        :formMode="formode"
        :resetable="resetable()"
        :opened.sync="modOpen"
        :modalType="'type00'"
      >
        <v-container>
          <v-layout wrap>
            <v-flex xs12 class="wrap__select" :class="{ disabled: formode === 'MOD' }">
              <v-select
                v-if="formode == 'NEW'"
                v-model="selectedItem.groupCode"
                dense
                required
                class="field"
                item-text="name"
                item-value="code"
                label="Group Title"
                placeholder="Select a Group"
                :menu-props="{contentClass:'single_select'}"
                :disabled="codePropagated.code !== 'ALL'"
                :items="groupOptions"
                :rules="groupCodeRules"
              ></v-select>
              <v-text-field v-else v-model="selectedItem.groupCode" required label="Group Title"></v-text-field>
            </v-flex>
            <v-flex xs12 id="inputCode" :class="{ disabled: formode === 'MOD' }">
              <v-text-field
                v-model="selectedItem.code"
                required
                placeholder="Edit after the Code input"
                label="Code"
                :rules="codeRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.name"
                required
                label="Code Title"
                placeholder="Input/Edit Title"
                @input="eventInput"
                :rules="nameRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.desc"
                required
                label="Code Description"
                placeholder="Input/Edit description"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </j-form-modal>
    </div>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

    <j-snackbar
      :snackbar="snackbar"
      :snackbarColor="'success'"
      :closeBtnColor="'buttonColor'"
      :positionX="'top'"
      :positionY="'right'"
      :top="'115px'"
      :timeout="2000"
      :mode="'mode'"
      :text="'Priority changed'"
    ></j-snackbar>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { SystemService } from "@/services"
import JFormModal from "@/components/floating/JFormModal"
import JSnackbar from "@/components/JSnackbar"

export default {
  name: "sysenv_code_index",
  components: {
    JFormModal,
    JSnackbar
  },
  data: () => ({
    systemService: null,
    snackbar: false,
    modOpen: false,
    selected: [],
    selectedIndex: -3,
    selectedItem: {},
    selectedPrevItem: [],
    headers: [
      { type: "drag", text: "", align: "right", value: "", width: 30 },
      {
        type: "text",
        text: "Group Title",
        align: "left",
        value: "groupName",
        width: 120
      },
      { type: "conf", text: "No", align: "center", width: 40 },
      { type: "text", text: "Code", align: "left", value: "code", width: 150 },
      { type: "text", text: "Title", align: "left", value: "name", width: 170 },
      { type: "text", text: "Description", align: "left", value: "desc" }
    ],
    codeIndex: [],
    loading: false,
    select: null,
    formode: __C.FORM.EDIT_MODE_NEW,
    valid: false,
    groupCodeRules: [v => !!v || "Required"],
    codeRules: [
      v => !!v || "Required",
      v => (!!v && /^[A-Z0-9_]*$/.test(v)) || 'Uppercase and "_" only',
      v => (!!v && v.length >= 2) || "At least 2 characters",
      v => (!!v && v.length < 50) || "Less then 50 characters"
    ],
    nameRules: [
      v => !!v || "Required",
      v => (!!v && v.length >= 1) || "At least 1 characters",
      v => (!!v && v.length < 50) || "Less then 50 characters"
    ],
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    groupOptions: [],
    yes: () => { }
  }),
  watch: {
    codePropagated: {
      handler(val) {
        this.headersChange(val) // headers 변경
        // to retain or set page's default status 'Add'
        this.selectedIndex = -3
        // sortable 작업 후 하위 메뉴 이동시 table 강제로 리로드
        this.codeIndex = [{ dummy: "dummy" }]

        this.systemService.getCodeIndex(val.code, this.setCodeIndex)
      },
      deep: true
    },
    "selectedItem.name"(val) {
      if (this.selectedIndex > -1) return
      if (!val) {
        this.selectedItem.code = ""
        return
      }
      let code = val
        .toUpperCase()
        .replace(/[^A-Z0-9_\s]/g, "")
        .replace(/[\s]/g, "_")
        .replace(/(_)\1+/g, "_")
      this.selectedItem.code = code
    }
  },
  created() {
    this.systemService = new SystemService()
    this.codeRules.push(v => this.isCodeValid(v) || "Duplicated")
    //this.systemService.getCodeIndex(this.codePropagated.code, this.setCodeIndex)
  },
  mounted() {
    // to avoid 'Access-Control-Allow-Origin' CORS policy error
    setTimeout(() => {
      this.systemService.getCodeGroup(res => {
        if (!res) this.groupOptions = []
        else this.groupOptions = res
        this.systemService.getCodeIndex(
          this.codePropagated.code,
          this.setCodeIndex
        )
      })
      this.headersChange(this.codePropagated) // headers 변경
    }, 200)
  },
  computed: {
    codePropagated: {
      get() {
        return this.$store.state.sysenv.codePropagated
      },
      set(value) {
        this.$store.commit("sysenv/codePropagate", value)
      }
    }
  },
  methods: {
    eventInput() {
      if (this.selectedItem.name != "" && !!this.selectedItem.name)
        document
          .getElementById("inputCode")
          .classList.toggle("disabled", false)
      else
        document.getElementById("inputCode").classList.toggle("disabled", true)
    },
    setCodeIndex(res) {
      this.modOpen = false

      if (!res) {
        res = []
      }

      // 'no' field is generated by v-data-table's items="props" action
      // because of that props.item.no is defined to the data-date' item template
      // it doesn't need to add manually...
      // res.forEach(item => { item['no'] = 0 })
      this.codeIndex = res

      // at the very first time access or page reloading,
      // it comes twise from 'watcher' and 'mounted' event
      if (this.selectedIndex < -1) {
        ++this.selectedIndex
        // --

        // if this action comes from that add new code index,
        // right added item should be selected manually.
      } else if (this.selectedIndex == -1) {
        this.setEdit(
          this.codeIndex.findIndex(x => x.code === this.selectedItem.code)
        );
      } else {
        // if this action comes from that delete existing code index,
        // select a forward or backward item.
        if (
          this.codeIndex.findIndex(x => x.code === this.selectedItem.code) < 0
        ) {
          if (this.codeIndex.length > 0) {
            if (this.codeIndex.length >= this.selectedIndex + 1) {
              // Nothing to do...
            } else {
              this.selectedIndex = this.codeIndex.length - 1
            }
            this.setEdit(this.selectedIndex)
          } else {
            // this.setAdd()
          }
        } else {
          this.setEdit(this.selectedIndex)
        }
      }
    },
    onRowMoved(val) {
      const rowSelected = this.codeIndex.splice(val.oldIndex, 1)[0] // Get the selected row and remove it
      this.codeIndex.splice(val.newIndex, 0, rowSelected) // Move it to the new index
      this.snackbar = false
      this.systemService.setPriority(
        {
          newIdex: val.newIndex,
          oldIndex: val.oldIndex,
          targetName: "CODE_INDEX",
          conditionName1: this.codePropagated.code
        },
        () => {
          this.snackbar = true
        }
      );
    },
    onSorted(items) {
      items.forEach((item, index) => {
        item.no = index + 1
      })
    },
    add() {
      this.yes = () => {
        this.msgOpen = false
        this.systemService.putCodeIndex(this.selectedItem, res => {
          this.systemService.getCodeIndex(
            this.codePropagated.code,
            this.setCodeIndex
          );
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Save Changes."
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to save new Code Index?"
      this.msgInfo.buttonText[0] = "Save"
      this.msgOpen = true
    },
    edit() {
      this.yes = () => {
        this.msgOpen = false;
        this.systemService.updCodeIndex(this.selectedItem, res => {
          this.systemService.getCodeIndex(
            this.codePropagated.code,
            this.setCodeIndex
          )
        })
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Save Changes."
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to save this changes?"
      this.msgInfo.buttonText[0] = "Save"
      this.msgOpen = true
    },
    del() {
      this.yes = () => {
        this.msgOpen = false;
        this.systemService.delCodeIndex(this.selectedItem, res => {
          this.systemService.getCodeIndex(
            this.codePropagated.code,
            this.setCodeIndex
          )
        })
        this.yes = () => { }
      };
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.titleDescription = "Important Notification"
      this.msgInfo.message = "Do you want to delete selected Code Index?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    querySelections(v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.codeIndex.filter(e => {
          return (
            (e.name || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
          )
        })
        this.loading = false
      }, 500)
    },
    propStatus(props) {
      this.selectedIndex = this.codeIndex.indexOf(props.item)
      this.selectedItem = Object.assign({}, props.item)

      if (this.selectedPrevItem.length > 0) {
        if (this.selectedPrevItem[0].code === props.item.code) {
          this.modOpen = true
          return
        } else {
          let _selected = props.item
          this.selected = [_selected]
        }
      } else {
        props.selected = !props.selected
      }
      this.selectedPrevItem = [...this.selected]
      this.formode = __C.FORM.EDIT_MODE_MOD
      this.modOpen = true
    },
    setAdd() {
      // at the very first time access or page reloading,
      // it comes twise from 'watcher' and 'mounted' event.
      // so it should be retained to be increased by them until being -1.
      this.selectedIndex = this.selectedIndex < -1 ? this.selectedIndex : -1
      this.selected = []
      this.selectedPrevItem = []
      this.formode = __C.FORM.EDIT_MODE_NEW
      this.$refs.formHandler.formReset()
      setTimeout(() => {
        this.selectedItem = {
          groupCode: this.codePropagated.code,
          groupName: this.codePropagated.name
        };
      }, 200)
      this.modOpen = true
    },
    setEdit(selectedIndex) {
      // myArray.map(x => x.hello).indexOf('stevie')
      this.selectedIndex = selectedIndex
      this.selectedItem = Object.assign({}, this.codeIndex[this.selectedIndex])
      this.selected = [Object.assign({}, this.codeIndex[this.selectedIndex])]
      this.selectedPrevItem = [...this.selected]
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    resetable() {
      if (this.selectedIndex < 0) {
        if (!!this.selectedItem.name || this.selectedItem.desc) return true
        return false
      } else {
        if (
          this.selectedPrevItem[0].name != this.selectedItem.name ||
          this.selectedPrevItem[0].desc != this.selectedItem.desc
        )
          return true
        return false
      }
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {}
        this.$refs.formHandler.formReset()
      } else {
        this.selectedItem = Object.assign({}, this.selectedPrevItem[0])
      }
    },
    cancel() {
      this.modOpen = false
    },
    isCodeValid(code) {
      if (this.formode == __C.FORM.EDIT_MODE_NEW) {
        return !this.codeIndex.find(item => item.code == code)
      } else {
        return true
      }
    },
    headersChange(val) {
      let groupIndex = this.headers.findIndex(h => h.value === "groupName")
      if (val.code === "ALL") {
        // headers no 변경
        let noIndex = this.headers.findIndex(h => h.value === "no")
        if (noIndex > -1) this.headers[noIndex].value = "groupSn"
        // headers Phase 추가
        if (groupIndex < 0)
          this.headers.unshift({
            type: "text",
            text: "Group Name",
            align: "left",
            value: "groupName",
            width: 170
          })
        // headers dag 제거
        let dragIndex = this.headers.findIndex(h => h.type === "drag")
        if (dragIndex > -1) this.headers.splice(dragIndex, 1)
      } else {
        // headers no 변경
        let snIndex = this.headers.findIndex(h => h.value === "groupSn")
        if (snIndex > -1) this.headers[snIndex].value = "no"
        // headers Phase 제거
        if (groupIndex >= 0) this.headers.shift()
        // headers dag 추가
        let dragIndex = this.headers.findIndex(h => h.type === "drag")
        if (dragIndex == -1)
          this.headers.unshift({
            type: "drag",
            text: "",
            align: "right",
            value: "",
            width: 30
          })
      }
    }
  }
}
</script>
